import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const productGroups = [
  'Trafik Sigortası',
  'Kasko',
  'Sağlık Sigortası',
  'Konut Sigortası',
  'Dask',
  'Ferdi Kaza',
  'Yol Yardım',
  'İMM'
];

const PerformanceDashboard = () => {
  const [salesData, setSalesData] = useState(null);
  const [dailyProductionData, setDailyProductionData] = useState([]);
  const [productSummary, setProductSummary] = useState({});
  const [analysis, setAnalysis] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  useEffect(() => {
    fetchSalesData();
    fetchDailyProductionData();
  }, []);

  useEffect(() => {
    if (salesData) {
      const summary = productGroups.reduce((acc, group) => {
        const groupSales = salesData.filter(sale => sale.product_name.toLowerCase() === group.toLowerCase());
        acc[group] = {
          count: groupSales.length,
          totalAmount: groupSales.reduce((sum, sale) => sum + parseFloat(sale.gross_premium), 0),
          totalCommission: groupSales.reduce((sum, sale) => sum + parseFloat(sale.commission), 0)
        };
        return acc;
      }, {});
      setProductSummary(summary);
    }
  }, [salesData]);

  const fetchSalesData = async () => {
    try {
      const response = await axios.get('https://zirvecrm.com/get_sales.php');
      if (response.data) {
        setSalesData(response.data);
      } else {
        throw new Error('Veri alınırken bir hata oluştu');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchDailyProductionData = async () => {
    try {
      const response = await axios.get('https://zirvecrm.com/performansverisi.php');
      console.log('API response:', response); // Yanıtı konsola yazdır
      if (response.data && response.data.success) {
        const formattedData = response.data.data.map(item => ({
          date: new Date(item.date).toLocaleDateString('tr-TR'),
          production: parseFloat(item.total_production)
        }));
        setDailyProductionData(formattedData);
      } else {
        throw new Error(response.data?.message || 'Günlük üretim verileri alınırken bir hata oluştu');
      }
    } catch (err) {
      console.error('Günlük üretim verileri alınırken hata:', err);
      setError(err.message || 'Bir hata oluştu');
    }
  };


  const formatCurrency = (value) => {
    const numberValue = parseFloat(value) / 100;
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberValue).replace('₺', '').trim();
  };

  const formatCommission = (value) => {
    const numberValue = parseFloat(value);
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberValue).replace('₺', '').trim();
  };

  const generateAnalysis = async () => {
    if (!salesData) return;

    setIsAnalyzing(true);
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey) {
      console.error("API anahtarı bulunamadı.");
      setIsAnalyzing(false);
      return;
    }

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'user',
              content: `3 cümle ile motive edici üretimini yorumla \n\n${JSON.stringify(salesData)}`
            },
          ],
          max_tokens: 150,
          temperature: 0.7,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
          },
        }
      );
      setAnalysis(response.data.choices[0].message.content);
    } catch (error) {
      console.error("Analiz oluşturulurken bir hata oluştu:", error);
      setAnalysis("Analiz oluşturulamadı. Lütfen daha sonra tekrar deneyin.");
    } finally {
      setIsAnalyzing(false);
    }
  };

  if (loading) return <p>Performans verileri yükleniyor...</p>;
  if (error) return <p>Hata: {error}</p>;
  if (!salesData) return <p>Veri bulunamadı.</p>;

  const totalCommission = salesData.reduce((total, sale) => total + parseFloat(sale.commission), 0) + 17002;

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    return date.toLocaleDateString('tr-TR', { day: 'numeric', month: 'short' });
  };


  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Performansım</h2>

      {/* Toplam Komisyon Tablosu */}
      <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-6 rounded-lg mb-6">
        <h3 className="text-xl font-semibold mb-2">Toplam Kazanılan Prim</h3>
        <p className="text-4xl font-bold">{formatCommission(totalCommission)} TL</p>
      </div>

      {/* Günlük Üretim Grafiği */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Günlük Üretim</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={dailyProductionData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date" 
              tickFormatter={(value) => new Date(value).toLocaleDateString('tr-TR', { day: 'numeric', month: 'short' })}
              interval={Math.ceil(dailyProductionData.length / 10)}
            />
            <YAxis tickFormatter={(value) => formatCurrency(value)} />
            <Tooltip 
              formatter={(value) => [formatCurrency(value), "Üretim"]}
              labelFormatter={(label) => `Tarih: ${label}`}
            />
            <Legend />
            <Bar dataKey="production" fill="#8884d8" name="Üretim" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Ürün Bazlı Satış ve Komisyon Özeti */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-4">Ürün Bazlı Satış ve Prim Özeti</h3>
        <table className="min-w-full bg-white">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Ürün</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Satış Adedi</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Toplam Tutar</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Toplam Prim</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {productGroups.map(group => (
              <tr key={group}>
                <td className="text-left py-3 px-4">{group}</td>
                <td className="text-left py-3 px-4">{productSummary[group]?.count || 0}</td>
                <td className="text-left py-3 px-4">{formatCurrency(productSummary[group]?.totalAmount || 0)} TL</td>
                <td className="text-left py-3 px-4">{formatCommission(productSummary[group]?.totalCommission || 0)} TL</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Performans Analizi Butonu */}
      <button
        onClick={generateAnalysis}
        disabled={isAnalyzing}
        className={`mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${isAnalyzing ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {isAnalyzing ? 'Analiz Yapılıyor...' : 'Performansımı Yorumla'}
      </button>

      {/* ChatGPT Analizi */}
      {analysis && (
        <div className="mt-6 bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Performans Analizi</h3>
          <p className="text-gray-700">{analysis}</p>
        </div>
      )}
    </div>
  );
};

export default PerformanceDashboard;