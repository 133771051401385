import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Shield, Home, User, Car, Wrench, Star } from 'lucide-react';

const TableCard = ({ title, children }) => (
  <div className="bg-white shadow-md rounded-lg p-4 mb-4">
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    {children}
  </div>
);

const ProductIcon = ({ type, count = 0 }) => {
  const icons = {
    'Dask': Shield,
    'Konut Sigortası': Home,
    'Ferdi Kaza': User,
    'Kasko': Car,
    'Yol Yardım': Wrench
  };

  const Icon = icons[type];
  const MAX_ICONS = 5;
  
  if (!Icon || !count) return (
    <div className="flex items-center gap-1">
      <Icon size={20} className="text-gray-400" />
      <span className="text-sm text-gray-400">0</span>
    </div>
  );

  const displayCount = Math.min(count, MAX_ICONS);
  const icons_array = Array(displayCount).fill(null);

  return (
    <div className="flex items-center gap-1">
      {icons_array.map((_, index) => (
        <Icon 
          key={index} 
          size={20} 
          className="text-blue-600"
        />
      ))}
      {count > MAX_ICONS && (
        <span className="text-sm font-medium text-blue-600">
          +{count - MAX_ICONS}
        </span>
      )}
    </div>
  );
};

const CompanyBalance = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const priorityCompanies = [
        'Ankara Sigorta',
        'Sompo Sigorta',
        'HDI Sigorta',
        'Unico Sigorta',
        'Magdeburger Sigorta',
        'Mapfre Sigorta',
        'Neova Sigorta',
        'Quick Sigorta',
        'Koru Sigorta',
        'Ray Sigorta',
        'Aveon Sigorta',
        'Hepiyi Sigorta',
        'GNC Yol Yardım'
      ];

      const groupCompaniesByPriority = (data) => {
        const priorityData = {};
        const otherData = {};
    
        Object.entries(data).forEach(([company, products]) => {
          const normalizedCompanyName = company.trim();
          if (priorityCompanies.some(pc => pc.toLowerCase() === normalizedCompanyName.toLowerCase())) {
            priorityData[company] = products;
          } else {
            otherData[company] = products;
          }
        });
    
        return { priorityData, otherData };
      };

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://zirvecrm.com/get_company_balance.php?month=${selectedMonth}&year=${selectedYear}`);
      setData(response.data || {});
    } catch (error) {
      console.error('Veri alınırken hata oluştu:', error);
      setData({});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedMonth, selectedYear]);

  const months = [
    { value: 1, label: 'Ocak' },
    { value: 2, label: 'Şubat' },
    { value: 3, label: 'Mart' },
    { value: 4, label: 'Nisan' },
    { value: 5, label: 'Mayıs' },
    { value: 6, label: 'Haziran' },
    { value: 7, label: 'Temmuz' },
    { value: 8, label: 'Ağustos' },
    { value: 9, label: 'Eylül' },
    { value: 10, label: 'Ekim' },
    { value: 11, label: 'Kasım' },
    { value: 12, label: 'Aralık' }
  ];

  const years = Array.from(
    { length: 5 },
    (_, i) => new Date().getFullYear() - i
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
      </div>
    );
  }

  const renderCompanyRow = (company, products = {}, isPriority = false) => (
    <tr key={company}>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center gap-2">
          {isPriority && (
            <Star size={16} className="text-yellow-400 fill-yellow-400" />
          )}
          <span className="text-sm font-medium text-gray-900">{company}</span>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <ProductIcon type="Dask" count={Number(products['Dask']) || 0} />
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <ProductIcon type="Konut Sigortası" count={Number(products['Konut Sigortası']) || 0} />
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <ProductIcon type="Ferdi Kaza" count={Number(products['Ferdi Kaza']) || 0} />
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <ProductIcon type="Kasko" count={Number(products['Kasko']) || 0} />
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <ProductIcon type="Yol Yardım" count={Number(products['Yol Yardım']) || 0} />
      </td>
    </tr>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
      </div>
    );
  }

  const { priorityData, otherData } = groupCompaniesByPriority(data);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-3xl font-semibold text-gray-800">Şirket Dağılımları</h2>
        
        <div className="flex gap-4">
          <select
            className="border rounded p-2"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(Number(e.target.value))}
          >
            {months.map(month => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>

          <select
            className="border rounded p-2"
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
          >
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      <TableCard title="Şirketlere Göre Ürün Dağılımı">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Şirket
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Dask
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Konut
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ferdi Kaza
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Kasko
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Yol Yardım
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {/* Öncelikli şirketler */}
              {priorityCompanies.map(company => {
                const normalizedCompany = Object.keys(priorityData).find(
                  key => key.toLowerCase() === company.toLowerCase()
                );
                return renderCompanyRow(
                  company,
                  normalizedCompany ? priorityData[normalizedCompany] : {},
                  true
                );
              })}
              
              {/* Ayırıcı çizgi */}
              {Object.keys(otherData).length > 0 && (
                <tr>
                  <td colSpan="6" className="px-6 py-4">
                    <div className="border-t border-gray-300"></div>
                  </td>
                </tr>
              )}

              {/* Diğer şirketler */}
              {Object.entries(otherData).map(([company, products]) => 
                renderCompanyRow(company, products, false)
              )}
            </tbody>
          </table>
        </div>
      </TableCard>
    </div>
  );
};

export default CompanyBalance;