import React, { useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

const ProductionCharts = ({ salesData, personnelData }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  const months = [
    { value: 1, label: "Ocak" },
    { value: 2, label: "Şubat" },
    { value: 3, label: "Mart" },
    { value: 4, label: "Nisan" },
    { value: 5, label: "Mayıs" },
    { value: 6, label: "Haziran" },
    { value: 7, label: "Temmuz" },
    { value: 8, label: "Ağustos" },
    { value: 9, label: "Eylül" },
    { value: 10, label: "Ekim" },
    { value: 11, label: "Kasım" },
    { value: 12, label: "Aralık" }
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

  // Ürün listelerini tanımlayalım
  const trafficProducts = ['trafik sigortası'];
  const nonTrafficProducts = [
    'sağlık sigortası',
    'dask',
    'imm',
    'yol yardım',
    'kasko',
    'konut',
    'ferdi kaza'
  ];

  const filterDataByMonth = (data) => {
    return data.filter(sale => {
      const saleDate = new Date(sale.created_at);
      return saleDate.getMonth() + 1 === selectedMonth;
    });
  };

  const calculateInsuranceTypeData = () => {
    const filteredData = filterDataByMonth(salesData);
    
    const trafficTotal = filteredData
      .filter(sale => 
        trafficProducts.some(product => 
          sale.product_name.toLowerCase().includes(product)
        )
      )
      .reduce((sum, sale) => sum + parseFloat(sale.gross_premium) / 100, 0);
    
    const nonTrafficTotal = filteredData
      .filter(sale => 
        nonTrafficProducts.some(product => 
          sale.product_name.toLowerCase().includes(product)
        )
      )
      .reduce((sum, sale) => sum + parseFloat(sale.gross_premium) / 100, 0);

    const total = trafficTotal + nonTrafficTotal;
    const trafficPercentage = total > 0 ? (trafficTotal / total) * 100 : 0;
    const nonTrafficPercentage = total > 0 ? (nonTrafficTotal / total) * 100 : 0;

    return [
      { name: 'Trafik', value: trafficTotal, percentage: trafficPercentage },
      { name: 'Trafik Dışı', value: nonTrafficTotal, percentage: nonTrafficPercentage }
    ];
  };

  const calculatePersonnelData = () => {
    const filteredData = filterDataByMonth(salesData);
    const personnelTotals = {};

    filteredData.forEach(sale => {
      const personnelName = personnelData[sale.personnel_id];
      const amount = parseFloat(sale.gross_premium) / 100;
      
      if (personnelName) {
        personnelTotals[personnelName] = (personnelTotals[personnelName] || 0) + amount;
      }
    });

    return Object.entries(personnelTotals).map(([name, value]) => ({
      name,
      value
    }));
  };

  const calculatePersonnelDetails = () => {
    const filteredData = filterDataByMonth(salesData);
    const personnelDetails = {};

    Object.entries(personnelData).forEach(([id, name]) => {
      const personalSales = filteredData.filter(sale => sale.personnel_id.toString() === id);
      
      const trafficTotal = personalSales
        .filter(sale => 
          trafficProducts.some(product => 
            sale.product_name.toLowerCase().includes(product)
          )
        )
        .reduce((sum, sale) => sum + parseFloat(sale.gross_premium) / 100, 0);
      
      const nonTrafficTotal = personalSales
        .filter(sale => 
          nonTrafficProducts.some(product => 
            sale.product_name.toLowerCase().includes(product)
          )
        )
        .reduce((sum, sale) => sum + parseFloat(sale.gross_premium) / 100, 0);

      const total = trafficTotal + nonTrafficTotal;
      const trafficPercentage = total > 0 ? (trafficTotal / total) * 100 : 0;
      const nonTrafficPercentage = total > 0 ? (nonTrafficTotal / total) * 100 : 0;

      personnelDetails[name] = {
        name,
        trafik: trafficTotal,
        trafikDisi: nonTrafficTotal,
        toplam: total,
        trafikYuzde: trafficPercentage,
        trafikDisiYuzde: nonTrafficPercentage
      };
    });

    return Object.values(personnelDetails)
      .sort((a, b) => b.toplam - a.toplam);
  };

  const formatValue = (value) => {
    return `${value.toLocaleString('tr-TR', { minimumFractionDigits: 2 })} TL`;
  };

  const formatPercentage = (value) => {
    return `%${value.toFixed(1)}`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          <p className="font-bold">{label}</p>
          {payload[0]?.name === 'trafik' ? (
            <>
              <p className="text-blue-600">Trafik: {formatValue(payload[0].value)}</p>
              <p className="text-green-600">Trafik Dışı: {formatValue(payload[1]?.value || 0)}</p>
              <p className="font-bold mt-2">
                Toplam: {formatValue((payload[0].value || 0) + (payload[1]?.value || 0))}
              </p>
            </>
          ) : (
            <p>{payload[0]?.name}: {formatValue(payload[0].value)}</p>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Üretim Dağılımı</h2>
          <select
            className="p-2 border rounded-md"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
          >
            {months.map(month => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
        </div>

        {/* Genel Dağılım Özeti */}
        <div className="mb-6 p-4 bg-gray-50 rounded-lg">
          <h3 className="text-lg font-semibold mb-3">Genel Üretim Dağılımı</h3>
          {calculateInsuranceTypeData().map((item, index) => (
            <div key={index} className="flex justify-between items-center mb-2">
              <span className="font-medium">{item.name}:</span>
              <span>
                {formatValue(item.value)} ({formatPercentage(item.percentage)})
              </span>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="h-80">
            <h3 className="text-lg font-semibold text-center mb-4">Trafik / Trafik Dışı Dağılımı</h3>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={calculateInsuranceTypeData()}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                  label={({ name, value, percentage }) => `${name}: ${formatValue(value)} (${formatPercentage(percentage)})`}
                >
                  {calculateInsuranceTypeData().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => formatValue(value)} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div className="h-80">
            <h3 className="text-lg font-semibold text-center mb-4">Personel Bazlı Dağılım</h3>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={calculatePersonnelData()}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                  label={({ name, value }) => `${name}: ${formatValue(value)}`}
                >
                  {calculatePersonnelData().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => formatValue(value)} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Personel Detaylı Üretim Grafiği */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Personel Bazlı Trafik/Trafik Dışı Üretim Detayları</h2>
        
        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={calculatePersonnelDetails()}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={(value) => `${(value / 1000).toFixed(1)}K`} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="trafik" name="Trafik" fill="#3B82F6" />
              <Bar dataKey="trafikDisi" name="Trafik Dışı" fill="#10B981" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Detaylı Tablo */}
        <div className="mt-8 overflow-x-auto">
          <table className="min-w-full border-collapse table-auto">
            <thead>
              <tr className="bg-gray-50">
                <th className="border p-3 text-left">Personel</th>
                <th className="border p-3 text-right">Trafik</th>
                <th className="border p-3 text-right">Trafik %</th>
                <th className="border p-3 text-right">Trafik Dışı</th>
                <th className="border p-3 text-right">Trafik Dışı %</th>
                <th className="border p-3 text-right">Toplam</th>
              </tr>
            </thead>
            <tbody>
              {calculatePersonnelDetails().map((person, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="border p-3 font-medium">{person.name}</td>
                  <td className="border p-3 text-right text-blue-600">{formatValue(person.trafik)}</td>
                  <td className="border p-3 text-right text-blue-600">{formatPercentage(person.trafikYuzde)}</td>
                  <td className="border p-3 text-right text-green-600">{formatValue(person.trafikDisi)}</td>
                  <td className="border p-3 text-right text-green-600">{formatPercentage(person.trafikDisiYuzde)}</td>
                  <td className="border p-3 text-right font-bold">{formatValue(person.toplam)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ProductionCharts;