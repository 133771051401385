import React, { useState, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import axios from 'axios';
import "tailwindcss/tailwind.css";
import { FaDollarSign, FaLightbulb } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

// pdf.js işçi dosyasını tanımla
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const DidYouKnow = () => {
  const facts = [
    "Türkiye'de trafik sigortası 1953 yılında zorunlu hale getirilmiştir.",
    "Kasko sigortası, aracınızı doğal afetlere karşı da korur.",
    "DASK, 1999 Marmara Depremi'nden sonra zorunlu hale getirilmiştir.",
    "Sağlık sigortası, koruyucu sağlık hizmetlerini de kapsar.",
    "Ferdi kaza sigortası, 7/24 dünya çapında geçerlidir.",
    "Konut sigortası, evinizin içindeki eşyaları da koruma altına alır.",
    "İMM sigortası, trafik sigortasının tamamlayıcısı niteliğindedir."
  ];

  const [fact, setFact] = useState('');

  useEffect(() => {
    setFact(facts[Math.floor(Math.random() * facts.length)]);
  }, []);

  return (
    <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4 rounded-lg shadow-md">
      <div className="flex items-center">
        <FaLightbulb className="text-yellow-400 mr-2" size={24} />
        <p className="font-bold">Bunu biliyor muydunuz?</p>
      </div>
      <p className="mt-2">{fact}</p>
    </div>
  );
};

const LoadingSpinner = () => (
  <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
    <AiOutlineLoading3Quarters className="animate-spin text-white text-4xl mb-4" />
    <h2 className="text-center text-white text-xl font-semibold">Yapay zeka poliçe bilgilerini çıkarıyor...</h2>
    <p className="w-1/3 text-center text-white">Bu işlem birkaç saniye sürebilir, lütfen bekleyin.</p>
  </div>
);

function normalizeText(text) {
  return text.toLowerCase()
    .replace(/\s+/g, ' ')
    .trim()
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

// Anahtar kelime eşleştirme tablosu
const urunAnahtarKelimeleri = {
  "Trafik Sigortası": ["trafik", "zorunlu trafik", "ZORUNLU MALİ SORUMLULUK", "karayolları motorlu araçlar", "zorunlu mali sorumluluk"],
  "Kasko": ["kasko", "tam kasko", "genişletilmiş kasko"],
  "Konut Sigortası": ["yuvam", "konutum", "evim", "konut", "Allianz Yuvam"],
  "Sağlık Sigortası": ["sağlık", "tamamlayıcı sağlık", "TAMAMLAYICI SAĞLIK SİGORTA POLİÇESİ", "FARK YOK TAMAMLAYICI SAĞLIK SİGORTASI"],
  "Dask": ["Deprem", "Afet"],
  "İMM": ["İhtiyari Mali Sorumluluk", "İMM"],
  "Yol Yardım": ["ARAÇ YOL YARDIM SİGORTASI", "ACİL SAĞLIK SİGORTA POLİÇESİ", "yol yardım", "yol yardım plus", "PLUS", "ferdi kaza"],
  "Ferdi Kaza": ["ACİL SAĞLIK SİGORTA POLİÇESİ", "ferdi kaza"],

};

// Ürün adını standartlaştırma fonksiyonu
function standartlastirUrunAdi(urunAdi) {
  const normalizedUrunAdi = normalizeText(urunAdi);
  for (const [standartAd, anahtarKelimeler] of Object.entries(urunAnahtarKelimeleri)) {
    if (anahtarKelimeler.some(kelime => normalizedUrunAdi.includes(normalizeText(kelime)))) {
      return standartAd;
    }
  }
  return urunAdi;
}

// Şirket adlarını standartlaştırmak için anahtar kelime eşleştirme tablosu
const companyKeywords = {
  "Ankara Sigorta": ["ankara anonim türk sigorta şirket", "ankara sigorta a.ş", "ankara sigorta", "ankara"],
  "Ak Sigorta": ["ak sigorta a.ş", "ak sigorta anonim şirketi", "aksigorta anonim şirketi", "ak sigorta", "aksigorta"],
  "Sompo Sigorta": ["sompo", "asıl sompo", "sompo japan", "sompo sigorta a.ş", "sompo sigorta anonim şirketi"],
  "Neova Sigorta": ["neova", "neova sigorta a.ş", "neova sigorta"],
  "Unico Sigorta": ["unico", "unico sigorta a.ş", "unico sigorta"],
  "GNC Yol Yardım": ["gnc", "gnc assist yardım ve destek hizmetleri danışmanlık a.ş", "gnc assist"],
  "Quick Sigorta": ["quick", "quick sigorta a.ş", "quick sigorta"],
  "Ray Sigorta": ["ray", "ray sigorta a.ş", "ray sigorta"],
  "Magdeburger Sigorta": ["magdeburger", "magdeburger sigorta a.ş", "magdeburger sigorta"],
  "Hdi Sigorta": ["hdi", "hdi sigorta", "hdi sigorta a.ş"],
  "Mapfre Sigorta": ["mapfre", "mapfre sigorta a.ş", "mapfre sigorta"],
  "Ana Sigorta": ["ana sigorta", "ana", "ana sigorta a.ş"],
  "Hepiyi Sigorta": ["hep iyi", "hepiyi", "hepiyi sigorta a.ş", "hepiyi sigorta"],
  "Türk Nippon Sigorta": ["türk nippon", "türk nippon sigorta a.ş", "türk nippon sigorta"],
  "Koru Sigorta": ["koru", "koru sigorta a.ş", "koru sigorta"],
  "Axa Sigorta": ["axa", "axa sigorta a.ş", "axa sigorta"],
  "Anadolu Sigorta": ["anadolu", "anadolu sigorta a.ş", "anadolu sigorta"],
  "Allianz Sigorta": ["allianz sigorta a.ş", "allianz", "allianz sigorta"],
  "Türkiye Sigorta": ["türkiye sigorta a.ş", "türkiye", "türkiye sigorta"],
  "Doğa Sigorta": ["Doğa sigorta a.ş", "doğa", "DOGA SİGORTA A.Ş", "Doğa Sigorta A.Ş.	"]

  // Diğer şirket adlarını burada ekleyebilirsiniz
};

// Şirket adını standartlaştırma fonksiyonu
function standartlastirSirketAdi(sirketAdi) {
  if (!sirketAdi) return sirketAdi;
  
  // Girdiyi küçük harfe çevir ve fazla boşlukları temizle
  const normalizedSirketAdi = normalizeText(sirketAdi);
  
  // Her bir standart şirket adı için kontrol et
  for (const [standartAd, anahtarKelimeler] of Object.entries(companyKeywords)) {
    // Tam eşleşme kontrolü
    if (anahtarKelimeler.includes(normalizedSirketAdi)) {
      return standartAd;
    }
    
    // Kısmi eşleşme kontrolü
    if (anahtarKelimeler.some(kelime => normalizedSirketAdi.includes(normalizeText(kelime)))) {
      return standartAd;
    }
  }
  
  // Eşleşme bulunamazsa orijinal değeri döndür
  return sirketAdi;
}

const PDFReader = () => {
  const [extractedData, setExtractedData] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [newOrRenewal, setNewOrRenewal] = useState('');
  const [commission, setCommission] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editField, setEditField] = useState(null);
  const [editValue, setEditValue] = useState('');
  // State eklemeleri
const [points, setPoints] = useState(null);
const [totalPoints, setTotalPoints] = useState(null);
const [level, setLevel] = useState(null);
const [reward, setReward] = useState(null);

  // Bu fonksiyonları bileşen içine taşıyoruz
  const startEditing = (field) => {
    setIsEditing(true);
    setEditField(field);
  };

  const stopEditing = () => {
    setIsEditing(false);
    setEditField(null);
    setEditValue('');
  };

  const handleEdit = (field, value) => {
    setExtractedData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  useEffect(() => {
    if (isEditing && editField) {
      setEditValue(extractedData[editField] || '');
    }
  }, [isEditing, editField, extractedData]);

  // Bu değişkenleri useMemo ile optimize edebiliriz
  const companyList = React.useMemo(() => Object.keys(companyKeywords), []);
  const productList = React.useMemo(() => Object.keys(urunAnahtarKelimeleri), []);

  // Komisyon oranları
  const commissionRates = {
    'Trafik Sigortası': { new: 0.003, renewal: 0.003 },
    'Kasko': { new: 0.006, renewal: 0.006 },
    'Konut Sigortası': { new: 0.035, renewal: 0.035 },
    'Sağlık Sigortası': { new: 0.020, renewal: 0.020 },
    'Dask': { new: 0.006, renewal: 0.006 },
    'Ferdi Kaza': { new: 0.005, renewal: 0.005 },
    'Yol Yardım': { new: 0.040, renewal: 0.040 },
    'İMM': { new: 0.010, renewal: 0.010 },
    // Diğer ürünler için oranlar...
  };

  function parseMoneyValue(value) {
    if (typeof value !== 'string') return 0;

    // Boşlukları kaldır
    value = value.replace(/\s/g, '');

    // Sayı olmayan karakterleri kaldır (rakamlar, nokta ve virgül hariç)
    value = value.replace(/[^\d.,]/g, '');

    // Hem virgül hem nokta içeriyor mu?
    const commaIndex = value.indexOf(',');
    const dotIndex = value.indexOf('.');

    if (commaIndex > -1 && dotIndex > -1) {
      if (commaIndex < dotIndex) {
        // '1,234.56' formatı (Amerikan/İngiliz)
        value = value.replace(/,/g, '');
        return parseFloat(value) || 0;
      } else {
        // '1.234,56' formatı (Avrupa/Türk)
        value = value.replace(/\./g, '');
        value = value.replace(',', '.');
        return parseFloat(value) || 0;
      }
    } else if (dotIndex > -1) {
      if (value.split('.').length > 2) {
        // Birden fazla nokta varsa, noktalar binlik ayırıcıdır
        value = value.replace(/\./g, '');
        return parseFloat(value) || 0;
      } else {
        // Tek nokta varsa, ondalık ayırıcı olabilir
        return parseFloat(value) || 0;
      }
    } else if (commaIndex > -1) {
      if (value.split(',').length > 2) {
        // Birden fazla virgül varsa, virgüller binlik ayırıcıdır
        value = value.replace(/,/g, '');
        return parseFloat(value) || 0;
      } else {
        // Tek virgül varsa, ondalık ayırıcı olabilir
        value = value.replace(',', '.');
        return parseFloat(value) || 0;
      }
    } else {
      // Ne virgül ne nokta varsa, doğrudan parse et
      return parseFloat(value) || 0;
    }
  }

  const calculateCommission = (productName, grossPremium, isNew) => {
    const rates = commissionRates[productName];
    if (!rates) return 0;
    const rate = isNew ? rates.new : rates.renewal;
    return grossPremium * rate;
  };

  const extractDataFromPDF = async (pdfFile) => {
    setLoading(true);
    setIsExtracting(true);
    try {
      const fileReader = new FileReader();
      fileReader.onload = async function () {
        const typedarray = new Uint8Array(this.result);
        const pdf = await pdfjsLib.getDocument(typedarray).promise;

        let text = "";

        for (let i = 1; i <= Math.min(3, pdf.numPages); i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          textContent.items.forEach((item) => {
            text += item.str + " ";
          });
        }

        const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

        if (!apiKey) {
          console.error("API anahtarı bulunamadı.");
          return;
        }

        let retries = 3;

        while (retries > 0) {
          try {
            const response = await axios.post(
              'https://api.openai.com/v1/chat/completions',
              {
                model: 'gpt-3.5-turbo',
                messages: [
                  {
                    role: 'user',
                    content: `Aşağıdaki PDF metninden şu bilgileri çıkar: Şirket Adı, Poliçe Numarası, Ürün Adı, Poliçe Net Primi, Poliçe Brüt Primi, Poliçe Başlangıç ve Bitiş Tarihi.
                    primleri çıkarırken basamakları şu formatta yaz. x.xxx,xx veya xx.xxx,xx

                    Ayrıca, Net Prim ve Brüt Prim şu şekilde ifade edilebilir:
- Net Prim: NET PRİM:, net prim:, Net Prim, toplam net prim, 
- Brüt Prim: Poliçe Primi, Ödenecek Brüt Tutar, Ödenecek Toplam Tutar, Ödenecek Tutar, Brüt Tutar, Brüt Prim, toplam

ÖNEMLİ : ! Net prim ve brüt prim kesinlikle şu şekilde ifade edilemez: "sigorta bedeli" veya "SİGORTA BEDELİ"
                    
                    Önemli: Şirket Adı olarak asla şu isimleri verme: SİGORTAMOLSUN, SİGORTAMOLSUN SİGORTA ARAC. HİZM. LTD. ŞTİ., CENTALYA, CENTALYA SİGORTA, SİGORTAMEMTALYA, KOBİ SİGORTA. Bunlar aracı kurum isimleridir, sigorta şirketi değildir.
Sigorta şirketlerinin ismini genelde pdf'in sağ üst veya sol üst köşelerinde logolarından tanıyabilirsin.

                   \n\n${text}`
                  },
                ],
                max_tokens: 300,
                temperature: 0.2,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${apiKey}`,
                },
              }
            );
            const content = response.data.choices[0].message.content;
            let parsedData;
            try {
              const lines = content.split('\n');
              parsedData = {};
              lines.forEach(line => {
                const [key, value] = line.split(': ');
                if (key && value) {
                  parsedData[key.trim()] = value.trim();
                }
              });

              const invalidCompanyNames = [
                "SİGORTAMOLSUN", "SİGORTAMOLSUN SİGORTA ARAC. HİZM. LTD. ŞTİ.",
                "CENTALYA", "CENTALYA SİGORTA", "SİGORTAMEMTALYA", "KOBİ SİGORTA"
              ];

              if (parsedData['Şirket Adı'] && invalidCompanyNames.some(name => parsedData['Şirket Adı'].includes(name))) {
                console.warn("Geçersiz şirket adı tespit edildi:", parsedData['Şirket Adı']);
                parsedData['Şirket Adı'] = "Bilinmeyen Sigorta Şirketi";
              }

              if (parsedData['Ürün Adı']) {
                parsedData['Ürün Adı'] = standartlastirUrunAdi(parsedData['Ürün Adı']);
              }

              if (parsedData['Şirket Adı']) {
                parsedData['Şirket Adı'] = standartlastirSirketAdi(parsedData['Şirket Adı']);
              }

              if (parsedData['Poliçe Başlangıç Tarihi']) {
                parsedData['Poliçe Başlangıç Tarihi'] = parsedData['Poliçe Başlangıç Tarihi'].split(' ')[0];
              }
              
              // Poliçe Bitiş Tarihi'nden saat bilgisini kaldır
              if (parsedData['Poliçe Bitiş Tarihi']) {
                parsedData['Poliçe Bitiş Tarihi'] = parsedData['Poliçe Bitiş Tarihi'].split(' ')[0];
              }

            } catch (parseError) {
              console.error("Veri işleme hatası:", parseError);
              parsedData = { error: "Veri işlenemedi", rawContent: content };
            }
            setExtractedData(parsedData);

            break;
          } catch (error) {
            console.error("PDF verisi işlenirken hata oluştu:", error);
            setMessage("PDF işlenirken bir hata oluştu.");
          } finally {
            setLoading(false);
            setIsExtracting(false);
          }
        }
      };
      fileReader.readAsArrayBuffer(pdfFile);
    } catch (error) {
      console.error("PDF verisi işlenirken hata oluştu:", error);
      setMessage("PDF işlenirken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      extractDataFromPDF(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      extractDataFromPDF(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    if (isSubmitting) return;
    if (!extractedData || extractedData.error) {
      setMessage("Kaydedilecek geçerli veri bulunamadı.");
      return;
    }
    if (!newOrRenewal) {
      setMessage("Lütfen poliçenin yeni iş mi yoksa yenileme mi olduğunu seçin.");
      return;
    }

    setIsSubmitting(true);
    setMessage('Poliçe kaydediliyor...');

    try {
      const isNew = newOrRenewal === 'Yeni İş';
      const grossPremium = parseMoneyValue(extractedData['Poliçe Brüt Primi']);
      const calculatedCommission = calculateCommission(
        extractedData['Ürün Adı'],
        grossPremium,
        isNew
      );

      const dataToSend = {
        ...extractedData,
        "Yeni mi Yenileme mi": newOrRenewal,
        "commission": calculatedCommission
      };
      console.log("Gönderilen veri:", dataToSend);
      const response = await axios.post('https://zirvecrm.com/save_sale.php', dataToSend);
      console.log("Sunucu yanıtı:", response.data);
      if (response.data.success) {
        setCommission(calculatedCommission);
        setPoints(parseFloat(response.data.points));
        setTotalPoints(response.data.total_points);
        setLevel(response.data.level);
        setReward(response.data.reward);
        setMessage('Poliçe başarıyla kaydedildi!');
        setIsSaved(true);
      } else {
        setMessage('Poliçe kaydedilirken bir hata oluştu: ' + (response.data.message || 'Bilinmeyen hata'));
      }
    } catch (error) {
      console.error('Poliçe kaydedilirken bir hata oluştu', error.response ? error.response.data : error);
      setMessage('Poliçe kaydedilirken bir hata oluştu: ' + (error.response ? error.response.data.message : error.message));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    setExtractedData(null);
    setNewOrRenewal('');
    setCommission(null);
    setMessage('');
    setIsSaved(false);
  };

  const handleOptionSelect = (option) => {
    setNewOrRenewal(option);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {isExtracting && <LoadingSpinner />}

      {!isSaved ? (
        <div
          className={`w-2/3 p-6 border-4 border-dashed rounded-lg bg-white shadow-md transition ${
            dragActive ? "border-blue-500" : "border-gray-300"
          }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type="file"
            accept="application/pdf"
            onChange={handleChange}
            className="hidden"
            id="input-file-upload"
          />
          <label
            htmlFor="input-file-upload"
            className="flex flex-col items-center justify-center w-full h-full cursor-pointer"
          >
            <div className="text-center">
              <p className="text-lg font-semibold text-gray-700">PDF Yüklemek için tıklayın veya sürükleyip bırakın</p>
              <p className="text-sm text-gray-500">Yalnızca PDF dosyalarını destekler</p>
            </div>
          </label>
        </div>
      ) : (
        <div className="w-2/3">
          <DidYouKnow />
          <button
            onClick={handleReset}
            className="w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Yeni Poliçe Yükle
          </button>
        </div>
      )}

      {loading && <p className="mt-4 text-blue-600">PDF işleniyor...</p>}

      {extractedData && !isSaved && (
        <div className="w-2/3 mt-6 p-4 bg-white rounded-lg shadow-md">
          <h3 className="text-xl font-bold text-gray-800 mb-4">Çıkarılan Bilgiler:</h3>
          {Object.entries(extractedData).map(([key, value]) => (
            <div key={key} className="mb-2 flex items-center">
              <span className="font-semibold">{key}:</span>
              <span className="ml-2">{value}</span>
              <button
                onClick={() => startEditing(key)}
                className="ml-2 text-blue-500 hover:text-blue-700"
              >
                Düzenle
              </button>
            </div>
          ))}
          {isEditing && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
              <div className="bg-white p-5 rounded-lg">
                <h4 className="text-lg font-bold mb-2">{editField} Düzenle</h4>
                {editField === 'Şirket Adı' ? (
                  <select
                    className="w-full p-2 border rounded"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                  >
                    <option value="">Seçiniz</option>
                    {companyList.map((company) => (
                      <option key={company} value={company}>
                        {company}
                      </option>
                    ))}
                  </select>
                ) : editField === 'Ürün Adı' ? (
                  <select
                    className="w-full p-2 border rounded"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                  >
                    <option value="">Seçiniz</option>
                    {productList.map((product) => (
                      <option key={product} value={product}>
                        {product}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                  />
                )}
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={() => {
                      handleEdit(editField, editValue);
                      stopEditing();
                    }}
                    className="mr-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Kaydet
                  </button>
                  <button
                    onClick={stopEditing}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    İptal
                  </button>
                </div>
              </div>
            </div>
          )}

          {!newOrRenewal ? (
            <div className="mt-4">
              <p className="mb-2 text-gray-700">Bu poliçe yeni iş mi yoksa yenileme mi?</p>
              <button
                onClick={() => handleOptionSelect('Yeni İş')}
                className="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                disabled={isSubmitting}
              >
                Yeni İş
              </button>
              <button
                onClick={() => handleOptionSelect('Yenileme')}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                disabled={isSubmitting}
              >
                Yenileme
              </button>
            </div>
          ) : (
            <button
              onClick={handleSave}
              className={`mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${
                isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Kaydediliyor...' : 'Poliçeyi Kaydet'}
            </button>
          )}
        </div>
      )}

{commission !== null && isSaved && (
    <div className="w-2/3 mt-4 p-4 bg-yellow-100 rounded-lg shadow-md flex flex-col">
        <div className="flex items-center mb-2">
            <FaDollarSign className="text-green-500 mr-2" size={24} />
            <span className="text-lg font-bold text-green-700">
                Kazandığınız Prim: {typeof commission === 'number' ? commission.toFixed(2) : commission} TL
            </span>
        </div>
        <div className="flex items-center">
            <div className="text-lg font-bold text-blue-700 mr-4">
                Bu poliçeden kazanılan puan: {typeof points === 'number' ? Math.floor(points/100) : points}
            </div>
            <div className="text-lg font-bold text-purple-700 mr-4">
                Toplam puanınız: {totalPoints ? Math.floor(totalPoints/100) : totalPoints}
            </div>
        </div>
        {level && (
            <div className="mt-2">
                <span className="font-bold text-gray-800">Seviye: </span>
                <span className="text-indigo-600">{level}</span>
                <span className="ml-2 text-green-600">(Prim:  TL)</span>
            </div>
        )}
        <p className="mt-2 text-sm text-gray-600">
            Harika iş! Bu satışla hedeflerinize bir adım daha yaklaştınız.
        </p>
    </div>
)}
      {message && <p className="mt-4 text-green-600">{message}</p>}
    </div>
  );
};

export default PDFReader;


//{reward}